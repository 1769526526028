<template>
  <v-layout class="row-message" ma-2 justify-end grow>
    <v-card class="message" :class="`${this.$vuetify.theme.dark ? 'dark-box-message' : 'light-box-message'}`">
      <v-layout grow>
        <v-layout
          class="box-message"
          column
        >
          <v-layout class="dont-break-out">
            <v-flex v-if="event.content">
              <v-layout
                v-if="
                  event.content_type == 'image' ||
                  event.content_type == 'image/png'
                "
                column
              >
                <div
                  style="
                    width: 330px;
                    max-height: 330px;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    max-width: 100%;
                    margin-bottom: 10px;
                    border-radius: 4px;
                  "
                >
                  <viewer
                    style="display: flex"
                    :images="[event.content.url]"
                    :options="{ movable: true, title: false, navbar: false }"
                  >
                    <img
                      v-for="src in [event.content.url]"
                      :src="src"
                      :key="src"
                      style="width: 100%; cursor: pointer"
                    />
                  </viewer>
                </div>
                <span v-if="event.content.caption">{{
                  event.content.caption
                }}</span>
              </v-layout>
              <v-layout v-else-if="event.content_type == 'ptt'" column>
                <audio-player :file="event.content.url">
                </audio-player>
              </v-layout>
              <v-layout v-else-if="event.content_type == 'audio'" column>
                <audio-player :file="event.content.url">
                </audio-player>
              </v-layout>
              <v-layout v-else-if="event.content_type == 'text'" column style="padding-top: 5px;">
                <span v-html="formattedMessage"></span>
              </v-layout>
              <v-layout v-else-if="event.content_type == 'quick_reply'" column style="padding-top: 5px;">
                <span v-html="event.content.text.replaceAll('\n','<br>')"></span>
              </v-layout>
              <v-layout v-else-if="event.content_type == 'video'" column>
                <video
                  width="300"
                  height="300"
                  controls
                  style="outline: none; margin-bottom: 10px; border-radius: 4px"
                >
                  <source
                    :src="event.content.url"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </v-layout>
              <v-layout v-else-if="event.content_type == 'document'" column>
                <v-layout ma-1 align-center>
                  <div style="height: 33px">
                    <img
                      v-if="checkDocType(event.content.file_name, ['pdf'])"
                      style="height: 100%"
                      src="icon_pdf.png"
                    />
                    <img
                      v-else-if="checkDocType(event.content.file_name, ['doc','docx',])"
                      style="height: 100%"
                      src="icon_doc.png"
                    />
                    <img
                      v-else-if="checkDocType(event.content.file_name, ['xls','xlsx','csv',])
                      "
                      style="height: 100%"
                      src="icon_sheet.png"
                    />
                    <img v-else style="height: 100%" src="icon_file.png" />
                  </div>

                  <span class="mr-1 ml-2">{{
                    event.content.file_name
                  }}</span>

                  <v-btn
                    outlined
                    icon
                    color="#848484"
                    @click="
                      downloadUri({ url: event.content.url, file_name: event.content.file_name || event.content.caption })
                    "
                  >
                    <v-icon>get_app</v-icon>
                  </v-btn>
                </v-layout>
              </v-layout>
              <v-layout v-else column class="keep-text">
                {{ event.content }}
              </v-layout>
            </v-flex>

            <v-flex grow></v-flex>

            <v-menu v-if="event.content_type == 'text' || event.content_type == 'quick_reply'" offset-y>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon style="font-size: 30px; " color="grey darken-1">
                    keyboard_arrow_down
                  </v-icon>
                </v-btn>
              </template>
              <v-list
                :class="`${
                  this.$vuetify.theme.dark
                    ? 'tertiary-background'
                    : 'secondary-background'
                }`"
              >
                <v-list-item @click="messageToReply(event.content.text)">
                  <v-list-item-title>
                    Responder
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            
            <v-flex class="pb-1 pl-2" shrink style="padding-top: 3px;">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-avatar :size="21" color="grey lighten-4" v-on="on">
                    <img
                      v-if="event.user"
                      :src="event.user.profile_photo_url"
                      alt="avatar"
                    />
                    <img
                      v-else
                      src="icon-rasa.png"
                      alt="avatar"
                    />
                    <!-- TODO colocar avatar-default caso nao tenha foto -->
                  </v-avatar>
                </template>
                <span v-if="event.user">{{
                  event.user.name
                }}</span>
                <span v-if="!event.user">Rasa</span>
              </v-tooltip>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-layout align-start justify-start v-on="on">
                  <v-icon outlined small :key="event.sent_at" v-if="!event.sent_at && !event.error_at">av_timer</v-icon>
                  <v-icon :color="doneAllIconColor(event.seen_at)" outlined small :key="event.seen_at" v-else-if="event.seen_at || event.sent_at">done_all</v-icon>
                  <v-icon outlined small color="red" :key="event.error_at" v-else>error</v-icon>
                </v-layout>
              </template>
              <span>{{
                event.status_from_delivery
              }}</span>
            </v-tooltip>

            <v-layout align-end justify-end>
              <v-layout
                shrink
                :class="`${this.$vuetify.theme.dark ? 'dark-box-message' : 'light-box-message'}`"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <span class="date-event" v-on="on">{{
                      (event.created_at) | moment("from", "now")
                    }}</span>
                  </template>
                  <span>{{
                    (event.created_at) | moment("DD/MM/YYYY HH:mm:ss")
                  }}</span>
                </v-tooltip>
                <!-- <status-sent-message
                  :message="event.referent"
                  :key="event.referent.id"
                /> -->
              </v-layout>        
            </v-layout>
          </v-layout>

        </v-layout>
      </v-layout>
    </v-card>
  </v-layout>
</template>

<script>
import { mapActions } from "vuex";

import { replyText } from "@/pages/CustomerServiceRasa/store/rasaReplyMessage.module"

export default {
  props: ["event", "debug"],
  setup() {
    const messageToReply = (message) => {
      message = message.replace(/^\n+/g, '').replace(/\n+$/g, '');
      message = message.replace(/\n+/g, '\n');

      message = message
                  .split('\n')
                  .filter(linha => !linha.trim().startsWith('>'))
                  .map(linha => `> ${linha}`)
                  .join('\n');
      
      message += '\n';
      replyText.value = message
    }

    return {
      messageToReply,
    };
  },
  computed: {
    formattedMessage() {
      // Divide a mensagem em linhas e aplica formatação às citações
      return this.event.content.text
        .split("\n")
        .map((line) => {
          if (line.startsWith(">")) {
            // Estilo para linhas que começam com '>'
            return `<div style="padding-left: 10px; border-left: 4px solid #34b7f1; font-size: 13px; font-style: italic; margin-bottom: 5px;">${line.substring(1).trim()}</div>`;
          }
          // Estilo padrão para linhas normais
          return `<div>${line}</div>`;
        })
        .join("");
    },
  },

  methods: {
    ...mapActions("rasaUpload", {
      downloadUri: "download",
    }),
    checkDocType(file_name, types) {
      let name_splitted = file_name.split(".");
      let type = name_splitted[name_splitted.length - 1];
      return types.indexOf(type) > -1;
    },
    doneAllIconColor(was_seen) {
      if (was_seen){ return 'blue' }
      else { return '' }
    },
  },
};
</script>

<style lang="scss" scoped>
.row-message {
  max-height: max-content;
  padding: 0;

  .message {
    border-radius: 15px;
    border-radius: 12px 12px 0 12px;

    .box-message {
      border-radius: 12px 12px 0 12px;
      padding: 8px 12px;
      min-width: 240px;
      max-width: 440px;
      z-index: 2;

      .dont-break-out {
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;

        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
      }

      .keep-text {
        max-width: 380px;
      }

      &.debug {
        border-radius: 12px 12px 12px 0;
      }
    }

    .date-event {
      font-size: 12px;
      margin-left: 10px;
    }

    .box-status {
      min-width: 17px;
      margin-top: -5px;
    }

    &.debug {
      border-radius: 12px 12px 12px 0;
    }
  }
  .light-box-message {
    background-color: #d9fdd3 !important;
    background: #d9fdd3 !important;
  }

  .dark-box-message {
    background-color: #005c4b !important;
    background: #005c4b !important;
  }
}
</style>
