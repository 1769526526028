<template>
  <v-navigation-drawer
    class="nav-drawer d-flex flex-column justify-center"
    :class="{ mini }"
    v-model="drawer"
    :mini-variant="mini"
    dark
    mini-variant-width="50"
    permanent
    clipped
    app
  >
    <v-layout column stretch fill-height>
      <v-list class="pa-1 ignore-vue-based">
        <v-list-item tag="div">
          <v-list-item-avatar @click.stop="mini = !mini">
            <img
              :src="loggedUser ? verifyImg(loggedUser.profile_photo_url) : ''"
            />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title
              >{{ loggedUser ? loggedUser.name : "" }}
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action v-if="!mini">
            <v-btn icon @click.stop="mini = !mini">
              <v-icon color="accent">chevron_left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-list class="pt-0 ignore-vue-based">
        <v-list-item 
          
          v-for="(item, i) in prioritizedAndTransformTypes"
          :key="i"
          :to="{ path: `/customerService2/${item.type}` }"
          :class="{ 'menu-active': $route.path.includes(`/customerService2/${item.type}`) }"
          @click="setCurrentServiceType(item.type)"
        >
          <v-list-item-action>
            <v-icon>{{setIconForlist(item.type)}}</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>{{item.value}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :to="{ path: '/customerService2/channel/list' }"
          class="active-color"
          :class="{ 'menu-active': $route.path.includes('/customerService2/channel/list') }"
        >
          <v-list-item-action>
            <v-icon>storage</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>Canais</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-spacer></v-spacer>
      
      <v-layout style="cursor: pointer" class="version-number" justify-center>
        <span @click="darkMode(!$vuetify.theme.dark)">
          <span v-if="!mini">Versão </span>
          <span>{{ $store.getters.appVersion }}</span>
        </span>
        <span @click="clearCache()">
          <span v-if="!mini">Atualizar Versao </span>
          <span><v-icon>{{setIconForlist('UPDATE_VERSION')}}</v-icon></span>
        </span>
      </v-layout>
    </v-layout>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { mdiWhatsapp, mdiUpdate } from '@mdi/js'
import { utils } from "@/assets/js/utils";

export default {
  async created() {
    await this.getServicesTypes()
  },
  data() {
    return {
      icons: {
        mdiWhatsapp,
        mdiUpdate
      },
      drawer: true,
      mini: true,
    };
  },
  methods: {
    darkMode(value) {
      this.$vuetify.theme.dark = value;
      document.cookie = `dark_mode = ${this.$vuetify.theme.dark}`;
    },
    clearCache() {
      console.debug('Limpando caches');

      caches.keys().then(function (names) {
        for (let name of names) {
          console.debug('Limpando cache storage:', name);
          caches.delete(name);
        }
      });

      window.location.reload()
    },
    setIconForlist(item) {
      if (item == 'WHATSAPP') {
        return this.icons.mdiWhatsapp
      }
      if (item == 'SMS') {
        return 'sms'
      }
      if (item == 'EMAIL') {
        return 'email'
      }
      if (item == 'SITE') {
        return 'language'
      }
      if (item == 'UPDATE_VERSION'){
        return this.icons.mdiUpdate
      }
    },
    ...mapMutations("rasaServices", {
      setCurrentServiceType: "setCurrentServiceType",
    }),
    ...mapActions("rasaServices", {
      getServicesTypes: "getServicesTypes",
    }),
    ...mapMutations("rasaServices", {
      setCurrentService: "setCurrentService",
    }),
  }, 
  computed: {
    loggedUser() {
      if (this.$store.getters.getLoggedUser) {
        return this.$store.getters.getLoggedUser;
      } else {
        return {};
      }
    },
    ...mapGetters("rasaServices", {
      prioritizedAndTransformTypes: "prioritizedAndTransformTypes",
      currentService: "currentService",
    }),
  },
  watch: {
    currentService(newValue, oldValue) {
      if (!newValue) return;
      if (!this.$store.getters.getLoggedUser.roles.includes(newValue.key)) {
        this.setCurrentService(null)
        this.$router.push('/not-permission')
        return
      }
    }
  }
};
</script>

<style lang="scss">
.beta-tag {
  font-size: 7px;
  font-weight: 800;
  position: absolute;
  margin-right: -5px;
  margin-top: -7px;
  text-transform: uppercase;
}
.active-color {
  color: #1de9b6;
  &:hover {
    color: #1de9b6;
  }
}
.dark-mode-toggle {
  transition: opacity 0.4s ease-in;
}
.v-navigation-drawer {
  height: 100%;
  z-index: 6;
  .v-avatar {
    margin-left: -12px;
  }
  .v-navigation-drawer--mini-variant .v-divider,
  .v-divider {
    display: block !important;
  }
  &.mini {
    .v-list__item {
      padding: 0;
    }

    .v-avatar {
      cursor: pointer;
      width: 30px !important;
      height: 30px !important;
      margin-left: -6px;

      &:hover {
        filter: brightness(110%);
      }
    }
  }

  .version-number {
    text-align: center;
    font-size: 12px;
    white-space: nowrap;
    color: #d6d6d6;
    position: absolute;
    bottom: 0;
    left: 50%;
    right: 50%;
    padding-bottom: 4px;
    flex: auto;
    flex-wrap: wrap;
  }

  .clickable {
    cursor: pointer;
  }
}

.menu-active {
  background-color: rgba(165, 165, 165, 0.05);
  div {
    color: #1de9b6 !important;
  }
}
</style>
