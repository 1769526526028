import RasaSessionsService from '@/services/sessions'
import RasaConversationService from '@/services/conversation'

const state = {
  list: [],
  selected: {},
  loading: true,
  total: null,
  intentsUntilNow: [],
  filters: {
    search: null,
    status: null,
    attendants: null,
    startDate: null,
    endDate: null,
    bad_classification: null,
    intent: null,
    initiative_key: null,
    quick_reply_id: null,
    without_initiatives: true,
  },
}
const mutations = {
  setSelected(state, item) {
    state.selected = item
  },
  clearSelected(state) {
    state.selected = {
      id: null,
      name: null,
      type: null,
    }
  },
  setList(state, list) {
    state.list = list
    state.total = list.length //arrumar isso aqui
  },
  setTotal(state, total) {
    state.total = total
  },
  setLoading(state, loading) {
    state.loading = loading
  },
  clearList(state){
    state.list = []
  },
  setIntentsUntilNow(state, payload) {
    state.intentsUntilNow = payload
  }
}
const actions = {
  async getAll({ commit }, params) {
    commit('setLoading', true)
    commit('clearList')
    await RasaSessionsService.get_sessions_by_params(params).then(async res => {
      for (let idx in res.data.sessions) {
        res.data.sessions[idx].last_message = await RasaSessionsService.get_last_message(res.data.sessions[idx].sender).then(res => res.data)
      }
      commit('setList', res.data.sessions)
      commit('setTotal', res.data.count)
    }).catch(() => {
      commit('showSnackbar','Ocoreu algum erro ao buscar as conversas', {root:true})
    }).finally(() => {
      commit('setLoading', false)
    })
    
  },

  async getIntents({ commit }) {
    const intents = await RasaConversationService.get_intents_until_now().then(res => res.data.intents_until_now)
    commit("setIntentsUntilNow", intents)
  }
}
const getters = {
  list(state) { return state.list },
  total(state) { return state.total },
  loading(state) { return state.loading },
  selected(state) { return state.selected },
  filters(state) { return state.filters },
  intentsUntilNow(state) { return state.intentsUntilNow },
  channelBuildVersion: (state) => (hash) => {
    return state.list.filter(item => item.agent_build_hash == hash)
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};