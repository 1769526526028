import router from '@/router'
import RasaConversationService from '@/services/conversation'
import RasaSessionsService from '@/services/sessions'
import { mountSessionById, mountMessagesBySender } from "@/assets/js/rasaBuildConversations"

const state = {
  currentConversation: {
    session: {
      id: null,
      customer: {
        name: null,
        document: null,
        crm_ref: null,
        suspect: null,
      }
    },
  },
  deals: [],
  loadingMessages: false,
  historyLoaded: [],
  showStatusHistory: false,
  isHistoryLoaded: false
}

const mutations = {
  setCurrentConversation(state, payload) {
    state.currentConversation = payload
  },

  resetCurrentConversation(state) {
    state.currentConversation = {
      session: {
        id: null,
        customer: {
          name: null,
          document: null,
          crm_ref: null,
          suspect: null,
        }
      },
    },
      state.deals = []
    router.replace({ params: { conversation_id: null } }).catch(() => { })
  },

  setLoadingMessages(state, payload) {
    state.loadingMessages = payload
  },
  pushMessage(state, payload) {
    state.currentConversation.messages.push(payload)
  },
  setMessageStatus(state, payload) {
    let msg_idx = state.currentConversation.messages.indexOf(
      state.currentConversation.messages.find((obj) => obj.id == payload.message.message_id && obj.type == payload.message.message_type)
    )
    if (msg_idx > -1) {
      if (payload.attribute == 'seen_at') {
        state.currentConversation.messages[msg_idx].seen_at = payload.updated_at

      } else if (payload.attribute == 'sent_at') {
        state.currentConversation.messages[msg_idx].sent_at = payload.updated_at

      } else if (payload.attribute == 'error_at') {
        state.currentConversation.messages[msg_idx].error_at = payload.updated_at
      }
    }
  },
  setMessageHistory(state, payload) {
    state.currentConversation.messages = payload
  },
  clearConversationHistory(state) {
    state.historyLoaded = []
  },
  pushDeals(state, deal) {
    state.deals.push(deal)
  },
  resetDeals(state) {
    state.deals = []
  },
  markAsBadClassification(state, message_recv_index) {
    state.currentConversation.messages[message_recv_index].bad_classification = true
  },
  changeShowStatusHistory(state, status){
    state.showStatusHistory = status
  },
  changeIsHistoryLoaded(state, isHistoryLoaded){
    state.isHistoryLoaded = isHistoryLoaded
  }
}

const actions = {
  async preLoad({ rootState, commit, state, dispatch }) {
    commit('setLoadingMessages', true)
    if (rootState.rasaSessions.sessions.length == 0) {
      dispatch('rasaSessions/registerConnection', { service_id: router.app._route.params.service_id }, { root: true })

    } else { dispatch('buildConversation') }
  },

  async buildConversation({ rootState, commit, state, dispatch }) {
    if (Number.isInteger(parseInt(router.app._route.params.conversation_id))) {
      let conversation_id = parseInt(router.app._route.params.conversation_id)
      const session_idx = rootState.rasaSessions.sessions.indexOf(
        rootState.rasaSessions.sessions.find((obj) => obj.session.id == conversation_id)
      )
      let session = null
      if (session_idx > -1) {
        session = rootState.rasaSessions.sessions[session_idx]
      } else {
        session = await mountSessionById(conversation_id, router.app._route.params.service_id, null).catch((res) => {
          commit('resetCurrentConversation')
          router.replace({ params: { conversation_id: null } }).catch(() => { })
          commit('showSnackbar', `Conversa não encontrada.`, { root: true })
        })
      }
      if (!session) {
        router.push('/not-found').catch(() => { })
        return
      }
      dispatch('changeCurrentConversation', session)

    } else {
      commit('resetCurrentConversation')
      router.replace({ params: { conversation_id: null } }).catch(() => { })
    }
    commit('setLoadingMessages', false)
  },


  async changeCurrentConversation({ rootState, commit, state, dispatch }, conversation) {
    if (conversation) {
      let session_exist = rootState.rasaSessions.sessions.find((obj) => {
        return obj.session.id == conversation.session.id
      })
      if (!session_exist) {
        session_exist = rootState.rasaClosedSessions.closedSessions.find((obj) => {
          return obj.session.id == conversation.session.id
        })
      }
      if (!session_exist) {
        session_exist = rootState.rasaSearchSessions.searchSessions.find((obj) => {
          return obj.session.id == conversation.session.id
        })
      }
      if (session_exist && session_exist.messages.length == 0) {
        conversation.messages = await mountMessagesBySender(conversation.session.sender, conversation.session.service_id, conversation.messages)

        commit('rasaSessions/pushManyMessages', { messages: conversation.messages, id: conversation.session.id }, { root: true })
        commit('setCurrentConversation', conversation)

        router.replace({ params: { conversation_id: conversation.session.id } }).catch(() => { })
        dispatch('rasaSessions/sessionWasReaded', conversation.session.id, { root: true })

      } else if (session_exist && session_exist.messages.length != 0) {
        commit('setCurrentConversation', conversation)
        router.replace({ params: { conversation_id: conversation.session.id } }).catch(() => { })
        dispatch('rasaSessions/sessionWasReaded', conversation.session.id, { root: true })

      } else if (router.app._route.params.conversation_id) {
        conversation.messages = await mountMessagesBySender(conversation.session.sender, conversation.session.service_id, conversation.messages)
        dispatch('rasaSessions/registerSession', conversation.session.id, { root: true })
        commit('setCurrentConversation', conversation)
        router.replace({ params: { conversation_id: conversation.session.id } }).catch(() => { })

      } else {
        commit('resetCurrentConversation')
        router.replace({ params: { conversation_id: null } }).catch(() => { })
      }
    }
  },

  async loadConversationHistory({ rootState, commit, state, dispatch }, { offset, limit, isHistoryLoaded }) {
    let messages = await mountMessagesBySender(state.currentConversation.session.sender, rootState.rasaServices.currentService.id, state.currentConversation.messages, offset, limit)
    commit('setMessageHistory', messages)
    state.historyLoaded.push(state.currentConversation.session.id)
    commit('changeIsHistoryLoaded', isHistoryLoaded)
  },
  async clearConversationHistory({ commit }) {
    commit('clearConversationHistory')
  },

  async changeShowStatusHistory({ commit }, status) {
    commit('changeShowStatusHistory', status)
  },

  async changeIsHistoryLoaded({ commit }, isHistoryLoaded) {
    commit('changeIsHistoryLoaded', isHistoryLoaded)
  },

  async clearCurrentConversation({ rootState, commit, state, dispatch }) {
    commit('resetCurrentConversation')
  },

  getDeals({ rootState, commit, state, dispatch }) {
    commit('resetDeals')
    RasaSessionsService.get_customer_deals(state.currentConversation.session.customer.crm_ref).then((res) => {
      res.data.deals.forEach(element => {
        commit('pushDeals', element)
      });
    })
  },
  badClassification({ commit, state }, message_recv_id) {
    let msg_idx = state.currentConversation.messages.indexOf(
      state.currentConversation.messages.find((obj) => obj.id == message_recv_id && obj.type == "received")
    )
    commit('markAsBadClassification', msg_idx)
  },
}

const getters = {
  currentConversation: (state) => state.currentConversation,
  historyLoaded: (state) => state.historyLoaded,
  deals: (state) => state.deals,
  showStatusHistory: (state) => state.showStatusHistory,
  isHistoryLoaded: (state) => state.isHistoryLoaded,
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};