import router from '@/router'
import RasaSessionsService from '@/services/sessions'
import { mountSessionById, mountMessagesBySender } from "@/assets/js/rasaBuildConversations"

const state = {
    closedSessions: [],
}

const mutations = {
    createClosedSessions(state, payload) {
        state.closedSessions.push(payload)
    },

    resetClosedSessions(state) {
        state.closedSessions = []
    },

    orderSessions(state) {
        state.closedSessions = state.closedSessions.sort((session1, session2) => {
          if(session1.session.updated_at > session2.session.updated_at) return -1
          return 1
        })
    },
    deleteSession(state, idx) {
        state.closedSessions.splice(idx, 1)
    },

}

const actions = {
	async getClosedSessions({ rootState, commit, state, dispatch }) {
        if (state.closedSessions.length == 0) {
            let params = {
                user_id: rootState.auth.loggedUser.id,
                fields_only: ['id', 'sender'],
                status: 'CLOSING,WAITING_CUSTOMER',
                limit: 10,
                order: '-updated_at',
                service_id: rootState.rasaServices.currentService.id,
            }

            let response = await RasaSessionsService.get_sessions_by_params(params).then(res => res.data)
            for (const idx in response.sessions) {
                let session = await mountSessionById(response.sessions[idx].id, rootState.rasaServices.currentService.id, response.sessions[idx].sender)
                
                session.messages = await mountMessagesBySender(session.session.sender, session.session.service_id, session.messages)
                commit('createClosedSessions', session)
            }
            if (state.closedSessions.length > 0) {
                commit('orderSessions')
                dispatch('rasaConversation/changeCurrentConversation', state.closedSessions[0], { root: true })
            } else {
                dispatch('rasaConversation/clearCurrentConversation', {},{ root: true })
            }
        }
    },
    async removeClosedConversation({ rootState, commit, state, dispatch }, idx) {
        commit('deleteSession', idx)
        dispatch('rasaConversation/changeCurrentConversation', state.closedSessions[idx], { root: true })
    }
}

const getters = {
    closedSessions: (state) => state.closedSessions,
  }
  
  export default {
      state,
      mutations,
      actions,
      getters,
      namespaced: true,
  };