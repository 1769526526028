import axios from '@/plugins/axios'

export default {

    get_messages(params) {
        return axios.rasaApi.get(`/messages`, { params })
    },

    get_sent_messages(params) {
        return axios.rasaApi.get(`/message_sent`, { params })
    },

    get_last_message(sender) {
        return axios.rasaApi.get(`/session/${sender}/last_message`)
    },

    get_session_status_history(session_ids) {
        const query = session_ids.map(id => `session_ids=${encodeURIComponent(id)}`).join('&');
        return axios.rasaApi.get(`/session/status_history`, { params: { session_ids: session_ids },
            paramsSerializer: params => {
              return Object.keys(params)
                .map(key => 
                  Array.isArray(params[key])
                    ? params[key].map(value => `${key}=${encodeURIComponent(value)}`).join('&')
                    : `${key}=${encodeURIComponent(params[key])}`
                )
                .join('&');
            },
           });
    },

    send_message(message) {
        return axios.rasaApi.post(`/message_sent`, message)
    },

    send_quick_reply(key, data) {
        return axios.rasaApi.post(`/quick_reply/${key}/send`, data)
    },

    send_initiative(initiative, service_key) {
        return axios.rasaApi.post(`/initiative/${service_key}/dispatch`, initiative)
    },

    mark_as_read(message_id) {
        return axios.rasaApi.patch(`/message_received/${message_id}/processed`)
    },

    mark_classification_as_wrong(message_id) {
        return axios.rasaApi.patch(`/message_received/${message_id}/bad_classification`)
    },

    get_intents_until_now() {
        return axios.rasaApi.get(`/message_received/intents_recognized`)
    },
}

