import router from '@/router'
import RasaSessionsService from '@/services/sessions'
import { mountSessionById, mountMessagesBySender } from "@/assets/js/rasaBuildConversations"

const state = {
    searchSessions: [],
    searchText: null
}

const mutations = {
    createSearchSessions(state, payload) {
        state.searchSessions.push(payload)
    },

    resetSearchSessions(state) {
        state.searchSessions = []
    },

    setSearchText(state, text) {
        state.searchText = text
    },
}

const actions = {
	async getSearchSessions({ rootState, commit, state, dispatch }) {
        commit('resetSearchSessions')
        let params = {
            like: state.searchText,
            service_id: rootState.rasaServices.currentService.id || null,
        }

        let response = await RasaSessionsService.get_sessions_by_search(params).then(res => res.data)
        
        for (const idx in response.sessions) {
            let session = await mountSessionById(response.sessions[idx].id, rootState.rasaServices.currentService.id, response.sessions[idx].sender)
            session.messages = await mountMessagesBySender(session.session.sender, session.session.service_id)
            commit('createSearchSessions', session)
        }
        if (state.searchSessions.length > 0) {
            dispatch('rasaConversation/changeCurrentConversation', state.searchSessions[0], { root: true })
        } else {
            dispatch('rasaConversation/clearCurrentConversation', {}, { root: true })
        }
    }
}

const getters = {
    searchSessions: (state) => state.searchSessions,
    searchText: (state) => state.searchText
  }
  
  export default {
      state,
      mutations,
      actions,
      getters,
      namespaced: true,
  };