<template>
  <v-layout class="chat__toolbar">
    <v-layout grow>
      <v-toolbar
        class="pa-0 secondary-background"
        align-center
        min-height="64px"
      >
        <v-layout column>
          <v-layout align-center>
            <v-layout
              style="min-width: 350px"
              class="d-flex flex-row align-center"
              v-if="currentConversation.session.customer.crm_ref"
              column
            >
              <div>
                <v-layout align-center>
                  <span class="font-weight-medium text-uppercase">
                    <b>{{ currentConversation.session.customer.name }}</b>
                  </span>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span
                        class="status-conversation"
                        :class="currentConversation.session.status"
                        v-on="on"
                        >{{
                          currentConversation.session.status
                            | conversationStatus
                            | firstLetter
                        }}</span
                      >
                    </template>
                    <span>
                      {{
                        currentConversation.session.status | conversationStatus
                      }}
                    </span>
                  </v-tooltip>
                </v-layout>
                <span class="font-weight-light document">{{
                  currentConversation.session.customer.document | document
                }}</span>
              </div>
              <v-btn
                class="ml-2"
                color="primary"
                @click.stop="openExternalRef"
                v-shortkey="['ctrl', '\\']"
                @shortkey="openExternalRef()"
                text
                small
              >
                CRM
              </v-btn>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    class="ml-2"
                    color="primary"
                    v-bind="attrs"
                    @click.stop="openGetDeals()"
                    text
                    small
                  >
                    Acordos
                  </v-btn>
                </template>
                <div v-if="listDeals">
                  <v-list
                    v-for="(item, index) in this.deals"
                    :key="index"
                  >
                    <v-list-item>
                      <v-list-item-title @click="openClosedDeals(item.id)"
                        >{{ item.creditor.name + " - " + item.id }}
                        <v-chip
                          v-if="isExpired(item.expires_in)"
                          color="black"
                          class="white--text ml-2 status-deal"
                          small
                        >
                          Expirado
                        </v-chip>
                        <v-chip
                          class="ml-2 status-deal white--text"
                          :class="item.status"
                          small
                        >
                          {{ item.status | dealStatus }}
                        </v-chip>
                        <v-chip
                          v-if="item.payments[0]?.boleto?.url"
                          class="ml-2"
                          @click.stop="sendBoleto(item.payments[0].boleto)"
                        >
                          Enviar boleto
                      </v-chip>
                      </v-list-item-title>
                    </v-list-item>
                    <v-divider color="#E0E0E0"></v-divider>
                  </v-list>
                </div>
              </v-menu>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    class="ml-2"
                    color="primary"
                    v-bind="attrs"
                    text
                    small
                  >
                    {{ currentConversation.session.source }}
                  </v-btn>
                </template>
                <v-list
                >
                  <v-list-item v-for="(item, idx) in sourceTypes" :key="idx">
                    <v-list-item-title
                      @click="changeSessionSource(item)"
                      style="cursor: pointer"
                      >{{ item }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-layout>
            <v-layout v-else-if="currentConversation.session.customer.empresas">
              <v-menu bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on="on" v-bind="attrs">Me Lembre</v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(link, empresa) in currentConversation.session
                      .customer.empresas"
                    :key="link"
                    :link="true"
                    :href="link"
                    target="_blank"
                  >
                    <v-list-item-title>
                      {{ empresa }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-layout>
            <v-layout style="min-width: 350px" v-else align-center>
              <v-btn
                class="add-info-btn text-span"
                text
                @click.stop="openEditConversation()"
                v-if="currentConversation.session.id"
                >Informar dados</v-btn
              >
              <v-tooltip bottom v-if="currentConversation.session.id">
                <template v-slot:activator="{ on }">
                  <span
                    class="status-conversation"
                    :class="currentConversation.session.status"
                    v-on="on"
                    >{{
                      currentConversation.session.status
                        | conversationStatus
                        | firstLetter
                    }}</span
                  >
                </template>
                <span>
                  {{ currentConversation.session.status | conversationStatus }}
                </span>
              </v-tooltip>
            </v-layout>
            <v-flex shrink>
              <v-layout column px-4>
                <v-layout justify-end align-end>
                  <v-avatar
                    :size="30"
                    color="grey lighten-4"
                    v-show="
                      currentConversation.session.user_id &&
                      currentConversation.session.user_id != loggedUser.id
                    "
                  >
                    <img
                      v-if="currentConversation.session.user"
                      :src="currentConversation.session.user.profile_photo_url"
                      alt="avatar"
                    />
                  </v-avatar>
                </v-layout>
              </v-layout>
            </v-flex>
            <v-layout shrink d-flex align-end justify-end>
              <v-btn
                v-if="currentConversation.session.id"
                color="primary"
                justify-center
                small
                class="mr-12"
                @click.stop="LoadFullHistory"
              >
                Carregar histórico
              </v-btn>
              <!-- this.currentConversation.last_event.type != 'INITIATIVE'" -->
              <v-btn
                class="mx-1"
                v-show="
                  isAssingToMeAndOpened &&
                  currentConversation.session.status == 'PENDING'
                "
                color="blue"
                dark
                @click.stop="openWaitingForDialog"
                v-shortkey="['ctrl', 'a']"
                @shortkey="openWaitingForDialog()"
                small
              >
                Aguardando
              </v-btn>

              <v-btn
                class="mx-1"
                v-show="
                  checkAttendent() &&
                  !['CLOSED', 'CLOSING', 'WAITING_CUSTOMER'].includes(
                    currentConversation.session.status
                  )
                "
                color="primary"
                @click.stop="changeStatus([currentConversation, 'CLOSING'])"
                v-shortkey="['ctrl', 'r']"
                @shortkey="changeStatus([currentConversation, 'CLOSING'])"
                small
              >
                Finalizar
              </v-btn>

              <v-btn
                class="mx-1"
                v-show="isAssingToMeAndNotOpened"
                color="primary"
                @click.stop="changeStatus([currentConversation, 'PENDING'])"
                small
              >
                <v-icon>play_arrow</v-icon>
                Reabrir
              </v-btn>

              <v-btn
                class="mx-1"
                v-show="isNotAssingToMe"
                color="blue"
                @click.stop="doAssing"
                small
                v-if="currentConversation.session.id"
              >
                <v-icon rigth>move_to_inbox</v-icon>
                Atribuir
              </v-btn>
              <v-dialog v-model="dialog" width="300" @keydown.esc="close">
                <template v-slot:activator="{ on }">
                  <div v-on.stop="on" @click.stop v-on="on">
                    <slot name="activator"></slot>
                  </div>
                </template>
                <v-card v-if="dialog" class="secondary-background">
                  <v-card-text class="d-flex flex-column pt-5">
                    <v-btn
                      class="mx-1"
                      color="blue"
                      dark
                      @click.stop="
                        changeStatus([currentConversation, 'WAITING_CUSTOMER'])
                      "
                      v-shortkey="['ctrl' + '1']"
                      @shortkey="
                        changeStatus([currentConversation, 'WAITING_CUSTOMER'])
                      "
                      text
                    >
                      <!-- <v-icon>replay_30</v-icon> -->
                      Aguardando cliente
                    </v-btn>

                    <v-btn
                      class="mx-1"
                      color="orange"
                      dark
                      @click.stop="
                        changeStatus([currentConversation, 'WAITING_TREATMENT'])
                      "
                      v-shortkey="['ctrl', '2']"
                      @shortkey="
                        changeStatus([currentConversation, 'WAITING_TREATMENT'])
                      "
                      text
                    >
                      Aguardando tratamento
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-layout>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list
                :class="`${
                  this.$vuetify.theme.dark
                    ? 'tertiary-background'
                    : 'secondary-background'
                }`"
              >
                <v-list-item
                  v-if="
                    !isNotAssingToMe &&
                    currentConversation.session.status == 'PENDING'
                  "
                  @click="openEditConversation()"
                >
                  <v-list-item-title>Editar conversa</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="isAdmin" @click="openSendToVacation()">
                  <v-list-item-title>Férias</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="!isNotAssingToMe && currentConversation.session.expired"
                  @click="isSessionExpired()"
                >
                  <v-list-item-title>Checar expirado</v-list-item-title>
                </v-list-item>
                <v-list-item @click="openNewSession()">
                  <v-list-item-title>Iniciar Conversa</v-list-item-title>
                </v-list-item>
                <v-list-item @click="toChangeShowStatusHistory()">
                  <v-list-item-title>
                    {{ showStatusHistory ? 'Desativar Status History' : 'Ativar Status History' }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-layout>
        </v-layout>
      </v-toolbar>
    </v-layout>
    <EditConversation ref="editConversation" />
    <SendToVacation ref="sendToVacation" />
    <NewSession ref="newSession" />
  </v-layout>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import environment from "@/assets/js/environment";
import EditConversation from "./EditConversation";
import SendToVacation from "./SendToVacation";
import NewSession from "./NewSession";
import moment from "moment";

export default {
  components: {
    EditConversation,
    SendToVacation,
    NewSession,
  },
  data() {
    return {
      dialog: false,
      historyActive: false,
      combobx: false,
      sourceTypes: ["AT", "RA", "ST"],
      offset: 0,
      listDeals: false,
    };
  },
  computed: {
    ...mapGetters("rasaConversation", {
      currentConversation: "currentConversation",
      historyLoaded: "historyLoaded",
      deals: "deals",
      showStatusHistory: "showStatusHistory"
    }),
    ...mapGetters({
      loggedUser: "getLoggedUser",
    }),
    isAdmin() {
      return this.loggedUser.roles.includes("admin");
    },
    isAssingToMeAndOpened() {
      return (
        this.currentConversation.session.user_id &&
        this.currentConversation.session.user_id == this.loggedUser.id &&
        this.currentConversation.session.status == "PENDING"
      );
    },
    isAssingToMeAndNotOpened() {
      return (
        this.currentConversation.session.user_id &&
        this.currentConversation.session.user_id == this.loggedUser.id &&
        ["WAITING_TREATMENT", "CLOSING", "WAITING_CUSTOMER"].includes(
          this.currentConversation.session.status
        )
      );
    },
    isNotAssingToMe() {
      return (
        !this.currentConversation.session.user_id ||
        this.currentConversation.session.user_id != this.loggedUser.id
      );
    },
  },
  mounted() {
    this.changeShowStatusHistory(false);
  },
  methods: {
    ...mapMutations({
      showSnackbar: "showSnackbar",
    }),
    ...mapActions("rasaSessions", {
      changeSessionStatus: "changeSessionStatus",
      assignToMe: "assignToMe",
      checkExpiration: "checkExpiration",
      changeSessionSource: "changeSessionSource",
    }),
    ...mapActions("rasaConversation", {
      loadConversationHistory: "loadConversationHistory",
      clearConversationHistory: "clearConversationHistory",
      getDeals: "getDeals",
      changeShowStatusHistory: "changeShowStatusHistory"
    }),
    ...mapActions("rasaEvents", {
      sendMessage: "send",
    }),
    async isSessionExpired() {
      await this.checkExpiration();
    },

    isExpired(date) {
      const vencimento = moment(date);
      let hoje = moment().startOf("day");
      const diasDeAtraso = vencimento.diff(hoje, "days");
      // console.log(diasDeAtraso);
      if (diasDeAtraso < 0) {
        return true;
      } else {
        return false;
      }
    },
    changeStatus(payload) {
      this.changeSessionStatus(payload);
      this.dialog = false;
    },

    openExternalRef() {
      let url =
        environment.externalRefUrl + this.currentConversation.session.crm_ref;
      window.open(url, "_blank");
    },
    openClosedDeals(deal) {
      let url = `${environment.backofficeUrl}/deal/${deal}?tab=1&skip_support=false`;
      window.open(url, "_blanck");
    },
    checkAttendent() {
      if (this.loggedUser?.id == this.currentConversation?.session.user_id)
        return true;
      return false;
    },
    openSendToVacation() {
      this.$refs.sendToVacation.open();
    },
    openNewSession() {
      this.$refs.newSession.open();
    },
    openEditConversation() {
      const payload = {
        user_id: this.currentConversation.session.user_id,
        name: this.currentConversation.session.customer.name,
        document: this.currentConversation.session.customer.document,
        crm_ref: this.currentConversation.session.customer.crm_ref,
        id: this.currentConversation.session.id,
      };
      this.$refs.editConversation.open(payload);
    },
    openWaitingForDialog() {
      this.dialog = true;
    },
    async LoadFullHistory() {
      this.historyActive = true;
      this.offset += 100;
      await this.loadConversationHistory({ offset: this.offset, isHistoryLoaded: true });
    },

    async doAssing() {
      if (this.loggedUser.in_vacation) {
        this.showSnackbar("Você esta de férias, aproveite!");
        return;
      }
      await this.assignToMe(this.currentConversation.session.id);
      window.location.reload();
    },
    async openGetDeals() {
      this.combobx = true;
      this.listDeals = true;
      await this.getDeals();
    },
    async toChangeShowStatusHistory(){
      await this.changeShowStatusHistory(!this.showStatusHistory);
    },
    async sendBoleto(boleto){
      let payload = {
        messages: [{
          sender: this.currentConversation?.session.sender,
          content_type: boleto.content_type,
          content: {
            file_name: boleto.original_name,
            url: boleto.url,
          },
          user_id: this.currentConversation?.session.user_id,
          session_id: this.currentConversation?.session.id,
      }]};
      await this.sendMessage(payload);
      payload = {};
      this.listDeals = false;
    }
  },
  watch: {
    currentConversation: {
      deep: true,
      async handler(newValue, oldValue) {
        if (newValue.session.sender != oldValue.session.sender) {
          this.historyActive = false;
          await this.changeShowStatusHistory(false);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.chat__toolbar {
  height: 64px;
  background: whitesmoke;
  z-index: 998;
}

.status-conversation {
  font-size: 11px;
  font-weight: 500;
  border-radius: 3px;
  margin-left: 5px;
  height: 17px;
  line-height: 17px;
  width: 17px;
  text-align: center;

  color: white;
  background-color: gray;

  &.PENDING {
    color: white;
    background-color: #2196f3;
  }

  &.AUTO {
    color: white;
    background-color: rgb(255, 0, 0);
  }

  &.WAITING_TREATMENT {
    color: white;
    background-color: #06f038;
  }

  &.WAITING_CUSTOMER {
    color: white;
    background-color: #34c6ff;
  }
  &.CLOSING {
    color: white;
    background-color: #ff8800;
  }
  &.CLOSE {
    color: white;
    background-color: #000000;
  }
}

.status-deal {
  font-size: 11px;
  font-weight: 500;

  &.CREATED {
    background-color: #2196f3;
  }

  &.STARTED {
    background-color: #4caf50;
  }

  &.WAITING {
    background-color: #6075b0;
  }

  &.AGREED {
    background-color: #b71ee9;
  }

  &.RESOLVED {
    background-color: #ff4081;
  }

  &.RENEGOTIATED {
    background-color: #673ab7;
  }

  &.ABORTED,
  &.expired {
    background-color: #212121;
  }

  &.not-notifiable {
    background-color: #ff9800;
  }

  &.waiting-cause {
    background-color: #dd2c00;
  }

  &.FOMENTATION {
    background-color: #630bff;
  }

  &.RENEGOTIATION {
    background-color: #cdb34a;
  }
}
</style>